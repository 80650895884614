<template>
  <div>
    <report-page
      id="Estimator Summary Report"
      :optionsname="$options.name"
    >
      <template #additional-filter>
        <span
          class="text-no-wrap font-weight-medium"
        >
          Filtered By:
        </span>
        <!-- Filtered By: Estimators -->
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="12"
            class="ml-1 text-no-wrap"
          >
            <h4
              v-if="getLengthOf(estimators.filtered_ids) <= 0"
              class="secondary--text"
            >
              No {{ displayAsPluralEstimator }} Selected
            </h4>
            <div
              v-else-if="users.isEstimator"
            >
              Estimator: {{ users.user.attributes.name }}
            </div>
            <div v-else>
              {{ (getLengthOf(estimators.filtered_ids) === getLengthOf(estimators.distinct) ? 'All' : 'Some') }} {{ displayAsPluralEstimator }}
            </div>
          </v-col>
        </v-row>
        <!-- Filtered By: Customers -->
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="12"
            class="ml-1 text-no-wrap"
          >
            <h4
              v-if="getLengthOf(customers.filtered_ids) <= 0"
              class="secondary--text"
            >
              No {{ displayAsPluralCustomer }} Selected
            </h4>
            <span v-else>
              {{ (getLengthOf(customers.filtered_ids) === getLengthOf(customers.data) ? 'All' : 'Some') }} {{ displayAsPluralCustomer }}
            </span>
          </v-col>
        </v-row>
        <!-- Filtered By: Statuses -->
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="12"
            class="ml-1 text-no-wrap"
          >
            <h4
              v-if="getLengthOf(filtered_status_codes) <= 0"
              class="secondary--text"
            >
              No Statuses Selected
            </h4>
            <span v-else>
              {{ (getLengthOf(filtered_status_codes) === statusCodeCount ? 'All' : 'Some') }} Statuses
            </span>
          </v-col>
        </v-row>
        <!-- Filtered By: No Dates -->
        <v-row
          v-if="getLengthOf(filtered_completed_dates) <= 1 && getLengthOf(filtered_invoiced_dates) <= 1"
          no-gutters
        >
          <v-col
            cols="12"
            sm="12"
            class="ml-1 text-no-wrap"
          >
            <h4 class="secondary--text">No Date Filters Set</h4>
          </v-col>
        </v-row>
        <!-- Filtered By: Completed Dates -->
        <v-row
          v-if="getLengthOf(filtered_completed_dates) > 1"
          no-gutters
        >
          <v-col
            cols="12"
            sm="4"
            class="ml-1 text-right"
          >
            Completed Dates:
          </v-col>
          <v-col
            sm="6"
            class="ml-2"
          >
            {{ displayCompletedDates }}
          </v-col>
        </v-row>
        <!-- Filtered By: Invoiced Dates -->
        <v-row
          v-if="getLengthOf(filtered_invoiced_dates) > 1"
          no-gutters
        >
          <v-col
            cols="12"
            sm="4"
            class="ml-1 text-right"
          >
            Invoiced Dates:
          </v-col>
          <v-col
            sm="6"
            class="ml-2"
          >
            {{ displayInvoicedDates }}
          </v-col>
        </v-row>
      </template>

      <template #additional-action-columns>
        <!-- Report Actions - Export to Excel -->
        <v-col
          lg="2"
          md="2"
          class="d-flex justify-center mb-3"
        >
          <download-excel
            class="btn btn-default"
            :data="exportPlans"
            worksheet="Estimator Productivity Report"
            name="Estimator Summary.xls"
          >
            <v-btn
              color="primary"
              min-width="100"
            >
              Export to Excel
            </v-btn>
          </download-excel>
        </v-col>
      </template>

      <template #print-area>
        <report-table-estimator-summary
          ref="reportTableEstimator"
        />
      </template>
    </report-page>

    <!-- Overlay is used to display spinner while page is loading -->
    <v-overlay :value="isBusy">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import ReportPage from '@/components/ReportPage'
  import SelectEstimator from '@/components/keys/SelectEstimator'
  import ReportTableEstimatorSummary from '@/components/maintenance/tables/ReportTableEstimatorSummary'
  // TODO: Estimator & Customer are similar enough they should be one component
  export default {
    name: 'EstimatorSummaryView',

    components: {
      ReportPage,
      SelectEstimator,
      ReportTableEstimatorSummary,
    },

    data () {
      return {
        filtered_status_codes: [],
        filtered_completed_dates: [],
        filtered_invoiced_dates: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'formatDateForTimezone',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      displayAsPluralEstimator: get('estimator/displayAsPlural'),
      displayAsPluralCustomer: get('customer/displayAsPlural'),
      users: get('user/users'),
      customers: get('customer/customers'),
      archives: get('archive/archives'),
      plans: get('plan/plans'),
      estimators: get('estimator/estimators'),
      isBusy () {
        return this.estimators.isBusy || this.plans.isBusy || this.archives.isBusy
      },
      statusCodeCount () {
        let itemLength = 0

        for (const key in this.plans.DashboardStatusCode) {
          itemLength++
        }

        return itemLength
      },
      displayCompletedDates () {
        return this.displayDateRange(this.filtered_completed_dates)
      },
      displayInvoicedDates () {
        return this.displayDateRange(this.filtered_invoiced_dates)
      },
      exportPlans () {
        const plans2Export = []

        for (const thisItem of this.plans.estimatorsSummary) {
          plans2Export.push({
            Estimator: thisItem.estimator_name,
            Customer: this.getCustomerName(thisItem.client_id),
            Plan: thisItem.description,
            Completed: this.formatDateForTimezone(thisItem.finish_date),
            SQFT: thisItem.heated_sqft,
            Rate: thisItem.total_rate,
            Price: `$${thisItem.fixed_pricing}`,
            Status: (thisItem.status_cd ? this.plans.StatusCode[thisItem.status_cd] : ''),
            Invoiced: this.formatDateForTimezone(thisItem.invoice_date),
            Paid: this.formatDateForTimezone(thisItem.paid_date),
          })
        }

        if (this.$refs.reportTableEstimator) {
          const sums = this.$refs.reportTableEstimator.getSums()

          plans2Export.push({
            Estimator: '',
            Customer: '',
            Plan: `# of Plans: ${this.getLengthOf(this.plans.estimatorsSummary)}`,
            Completed: '',
            SQFT: sums.display_sqft_sum,
            Rate: '',
            Price: sums.display_price_sum,
            Status: '',
            Invoiced: '',
            Paid: '',
          })
        }

        return plans2Export
      },
    },

    created () {
      this.$store.dispatch('archive/retrieveAll')

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/push2EstimatorsSummary':
            if (mutation.payload) {
              if ({}.hasOwnProperty.call(mutation.payload, 'status_codes')) {
                this.filtered_status_codes = [...mutation.payload.status_codes]
              }

              if ({}.hasOwnProperty.call(mutation.payload, 'completed_dates')) {
                this.filtered_completed_dates = [...mutation.payload.completed_dates]
              }

              if ({}.hasOwnProperty.call(mutation.payload, 'invoiced_dates')) {
                this.filtered_invoiced_dates = [...mutation.payload.invoiced_dates]
              }
            }

            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      displayDateRange (arrDate) {
        let display

        if (this.getLengthOf(arrDate) > 1) {
          const startDt = this.formatDateForTimezone(arrDate[0])
          const endDt = this.formatDateForTimezone(arrDate[1])

          display = `${startDt} - ${endDt}`
        }

        return display
      },
    },
  }
</script>

<style>
.black--text /deep/ label {
  color: black;
}
</style>
